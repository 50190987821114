/**
 *
 * @param {string} path
 */
export default function asset(path) {
  return `https://flp-assets.nyc3.digitaloceanspaces.com/zoe/${path.replace(
    /^\//,
    ""
  )}`
}
