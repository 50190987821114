import React from "react"
import Seo from "../components/seo"
import asset from "../lib/asset"
import Layout from "../components/layout"

export default function () {
  return (
    <Layout>
      <Seo title="Génesis" />
      <h1>Tipos y Sombras En El Antiguo Testamento </h1>
      <p>Abajo están las clases transcritas. </p>
      <h2>Tipos y Sombras en Génesis</h2>
      <table
        className="sermon_table"
        width="514"
        border="0"
        cellSpacing="0"
        cellPadding="0"
      >
        <tbody>
          <tr>
            <td>001</td>
            <td>Introducción de Tipos y Sombras </td>
            <td width="51">
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/001TS_Introduccion.pdf")}>
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td width="29">002</td>
            <td width="434">Tres Categorías Principales de Tipos y Sombras </td>
            <td>
              <div align="center"></div>{" "}
              <div align="center">
                <a href={asset("tiposysombras/002TS_Tres_Categorias.pdf")}>
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>003 </td>
            <td>La Creación del Mundo </td>
            <td>
              <div align="center"></div>{" "}
              <div align="center">
                <a href={asset("tiposysombras/003TS_Creacion.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>004</td>
            <td>La Noche Y El Día</td>
            <td>
              <div align="center"></div>{" "}
              <div align="center">
                <a href={asset("tiposysombras/004TS_Noche_Y_Dia.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>005</td>
            <td>Adán Y Eva </td>
            <td>
              <div align="center"></div>{" "}
              <div align="center">
                <a href={asset("tiposysombras/005TS_Adan_Y_Eva.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>008</td>
            <td>La Mentira </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/008TS_Mentira.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>009</td>
            <td> La Maldición 1 </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/009TS_Maldicion01.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>010</td>
            <td> La Maldición 2 </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/010TS_Maldicion02.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>011</td>
            <td> Caín y Abel </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/011TS_Cain_Y_Abel.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>012</td>
            <td> Las Tres Creaciones </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/012TS_Tres_Creaciones.pdf")}>
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>013</td>
            <td>La Gloria parte 1 ~ &iquest;Qué es Gloria?</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/013TS_Gloria01.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>014</td>
            <td> La Gloria parte 2 ~ La Gloria Es De Dios y Para Dios </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/014TS_Gloria02.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>015</td>
            <td> La Gloria parte 3 ~ Dos Tipos de Gloria </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/015TS_Gloria03.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>016</td>
            <td> Noé 1 </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/016TS_Noe01.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>017</td>
            <td> Noé 2 </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/017TS_Noe02.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>018</td>
            <td> Noé 3 </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/018TS_Noe03.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>019</td>
            <td>Los Pactos de Dios y La Torre de Babel </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/019TS_Pactos.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>020</td>
            <td>Introducción de Abraham </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/020TS_Abraham01.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>021</td>
            <td>Abraham y Su Viaje de Fe </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/021TS_Abraham02.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>022</td>
            <td>
              Melquisedec - Como Los Escritores de La Biblia Entienden Los Tipos
              y Sombras{" "}
            </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/022TS_Melquisedec.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>023</td>
            <td>La Promesa Hecha a Abraham </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/023TS_LaPromesa.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>024</td>
            <td>Aspectos de La Promesa 1 </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/024TS_Aspectos01.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>025</td>
            <td>Aspectos de La Promesa 2 </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/025TS_Aspectos02.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>026</td>
            <td>Isaac e Ismael 1 </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/026TS_IsaacIsmael01.pdf")}>
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>027</td>
            <td>Isaac e Ismael 2 </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/027TS_IsaacIsmael02.pdf")}>
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>032</td>
            <td>Circuncisión 1 </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/032TS_Circuncision01.pdf")}>
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>033</td>
            <td>Circuncisión 2 </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/033TS_Circuncision02.pdf")}>
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>034</td>
            <td>Sodoma y Gomorra - No Mirar Atrás</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/034TS_SodomaYGomorra.pdf")}>
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>035</td>
            <td>El Sacrificio de Isaac </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/035TS_SacrificioDeIsaac.pdf")}>
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>036</td>
            <td>La Sepultura de Sara ~ Rebeca La Esposa Para Isaac </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a
                  href={asset("tiposysombras/036TS_SepulturaDeSara_Rebeca.pdf")}
                >
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>037</td>
            <td>Jacob Y Esaú </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/037TS_JacobYEsau.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>038</td>
            <td>Lea Y Raquel </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/038TS_LeaYRaquel.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>039</td>
            <td>Jacob 1 </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/039TS_Jacob01.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>040</td>
            <td>Jacob 2 </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/040TS_Jacob02.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>041</td>
            <td>José 1 </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/041TS_Jose01.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>042</td>
            <td>José 2 </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/042TS_Jose02.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>043</td>
            <td>José 3 </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/043TS_Jose03.pdf")}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>044</td>
            <td>Dina</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset("tiposysombras/044TS_Dina.pdf")}>texto</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Layout>
  )
}
